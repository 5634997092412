import {FC} from 'react'
import InlineSVG from 'react-inlinesvg'
import {GButton, GModal} from 'src/app/components/Libs'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers.utils'
import {cn} from 'src/app/utils/cn.utils'

const AlertModal: FC<any> = ({
  show,
  setShow,
  onSubmit,
  header,
  body,
  confirmMessage,
  cancelMessage,
  modalClassname,
}) => {
  return (
    <GModal
      show={show}
      onHide={() => setShow(false)}
      className={cn('w-[400px] min-h-[280px] max-h-[400px]', modalClassname)}
    >
      <div className='relative flex flex-col justify-between w-full h-full p-6'>
        <div>
          <div>
            <InlineSVG src={toAbsoluteUrl('/media/image/image_warning.svg')} />
          </div>

          <div className='mt-5'>
            <div className='font-medium text-neutral-900 text-fs-7'>{header}</div>
            <div className='mt-1 text-neutral-500 text-fs-9'>{body}</div>
          </div>
        </div>

        <div className='flex items-center justify-between gap-3 mt-8'>
          <GButton
            type='button'
            className='w-full p-2 text-fs-9 border-neutral-300 text-neutral-700 whitespace-nowrap'
            variant='OUTLINED'
            onClick={() => setShow(false)}
          >
            {cancelMessage ?? 'Batal'}
          </GButton>
          <GButton type='button' className='w-full p-2 text-fs-9' onClick={onSubmit}>
            {confirmMessage ?? 'Buat'}
          </GButton>
        </div>
      </div>
    </GModal>
  )
}

export default AlertModal
