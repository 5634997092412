import {Order} from '../_api/api.types'

export enum EJobStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum EJobProgress {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
  REJECTED = 'REJECTED',
  DRAFT = 'DRAFT',
  COMPLETED = 'COMPLETED',
  SUBMITTED = 'SUBMITTED',
}

export enum EJobPriority {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
  CRITICAL = 'CRITICAL',
}

export enum EFormJobType {
  TEXT = 'TEXT',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  DROPDOWN = 'DROPDOWN',
  IMAGE = 'IMAGE',
  MULTIPLE_IMAGES = 'MULTIPLE_IMAGES',
  DATE = 'DATE',
  TIME = 'TIME',
}

export enum EFormJobTimeRule {
  FIXED = 'FIXED',
  FLEXIBLE = 'FLEXIBLE',
  NO_TIME = 'NO_TIME',
}

export interface IJob {
  id: string
  job_id: string
  name: string
  time_rule?: keyof typeof EFormJobTimeRule
  start_date: string
  end_date: string
  start_time: string
  end_time: string
  total_task: string
  status: keyof typeof EJobStatus
  progress: keyof typeof EJobProgress
  priority: keyof typeof EJobPriority
  is_recurring: boolean
}

export interface IJobDetail extends IJob {
  wo_number: string
  address: string
  description: string
  location: string
  location_name: string
  job_type: string
  pic_agent: {
    id?: string
    name?: string
  }
  has_approval: boolean
  approved_by: {
    id?: string
    name?: string
    type?: string
  }[]
  approval: {
    approval_date: string
    approved_by: string
    reason: string
    status: keyof typeof EJobProgress
  }[]
  support_agents: {id?: string; name?: string}[]
  has_attendance: boolean
  attendance?: {
    is_selfie: boolean
    radius: number
  }
  forms: ITaskRequest[]
  clock_in?: string
  clock_in_selfie?: string
  clock_out?: string
  clock_out_selfie?: string
  is_overdue?: boolean
}

export interface IJobRequest {
  name?: string
  time_rule?: keyof typeof EFormJobTimeRule
  start_date?: string | null
  end_date?: string | null
  start_time?: string | null
  end_time?: string | null
  is_recurring?: boolean
  priority?: keyof typeof EJobPriority
  wo_number?: string
  description?: string
  address?: string
  location?: string
  location_name?: string
  job_type?: string
  pic_agent?: string
  support_agents?: string[] | null
  has_attendance?: boolean
  attendance?: {
    radius?: number | null
    is_selfie?: boolean
  } | null
  approval_by?:
    | {
        id?: string
        type?: string
      }[]
    | null
  forms?: ITaskRequest[]
  status?: 'OPEN' | 'DRAFT'
}

export interface IJobQuery {
  search?: string
  page?: number
  size?: number
  sort?: string
  order?: Order
  statuses?: string[]
  priorities?: string[]
  progresses?: string[]
  start_date?: string
  end_date?: string
}

interface IJobBulkValue {
  id?: string
  value: any
  is_valid: boolean
}
export interface IJobBulkRequest {
  name: IJobBulkValue
  start_time: IJobBulkValue
  end_time: IJobBulkValue
  start_date: IJobBulkValue
  end_date: IJobBulkValue
  priority: IJobBulkValue
  wo_number: IJobBulkValue
  location: IJobBulkValue
}

export interface ITaskRequest {
  name?: string
  type?: keyof typeof EFormJobType
  description?: string
  data?: {
    id?: string
    name?: string
  }[]
  value?: string
  multiple_images?: {
    image: string
    image_description: string
  }[]
}
