import OnboardingRedux from 'src/app/store/Onboarding/OnboardingRedux'

export const extractAdminErrors = (detailed: Record<string, string>) => {
  const adminErrors: Record<string, string> = {}
  Object.entries(detailed).forEach(([key, value]) => {
    if (key.startsWith('admin_data.')) {
      const newKey = key.replace('admin_data.', '')
      adminErrors[newKey] = value
    }
  })
  return adminErrors
}

export const extractUsersErrors = (detailed: Record<string, string>) => {
  const usersErrors: any[] = []
  Object.entries(detailed).forEach(([key, value]) => {
    if (key.startsWith('users.')) {
      const match = key.match(/^users\.(\d+)\.(.+)$/)
      if (match) {
        const [, index, field] = match
        const idx = parseInt(index, 10)
        usersErrors[idx] = usersErrors[idx] || {}
        usersErrors[idx][field] = value
      }
    }
  })
  return usersErrors
}

export const extractAgentErrors = (e: any) => {
  const agentErrors: Record<string, string> = {}
  const errorsArray = e.response?.data?.response_output?.errors || []
  errorsArray.forEach((error: {field: string; code: string; message: string}) => {
    if (error.code === 'MOBILE_NUMBER_ALREADY_USED') {
      agentErrors['mobile_number'] = error.message
    }
    if (error.code === 'EMAIL_ALREADY_USED') {
      agentErrors['email'] = error.message
    }
  })
  return agentErrors
}

export const processDetailedErrors = (detailed: Record<string, string>, e: any) => {
  const adminErrors = extractAdminErrors(detailed)
  const usersErrors = extractUsersErrors(detailed)
  const agentErrors = extractAgentErrors(e)
  const quotaErrors = detailed['account_quota'] ?? null

  return {
    admin_data: adminErrors,
    users: usersErrors,
    agent: agentErrors,
    quota: quotaErrors,
  }
}

export const userPayload = (state: OnboardingRedux.IOnboardingState) =>
  state?.users && state?.users.length > 0
    ? state.users
        .filter((user) =>
          Object.entries(user).every(([key, value]) => key === 'last_name' || value !== '')
        )
        .map((user) => ({
          level: user.level,
          level_name: user.level_name,
          first_name: user.first_name,
          last_name: user.last_name,
          name: (user.first_name as string) + (user.last_name && ` ${user.last_name}`),
          email: user.email,
          mobile_number: `+${user.mobile_number}`,
        }))
    : []

export const checkAgentEmpty = (state: OnboardingRedux.IOnboardingState) =>
  !state?.agent?.first_name && !state?.agent?.mobile_number && !state?.agent?.email

export const endPayload = (token: string, state: OnboardingRedux.IOnboardingState) => ({
  activation_code: token,
  admin_data: {
    email: state?.admin_data?.email,
    mobile_number: `+${state?.admin_data?.mobile_number}`,
    first_name: state?.admin_data?.first_name,
    last_name: state?.admin_data?.last_name,
    name:
      (state?.admin_data?.first_name as string) +
      (state?.admin_data?.last_name && ` ${state?.admin_data?.last_name}`),
    password: state?.admin_data?.password,
  },
  users: userPayload(state),
  agents: checkAgentEmpty(state)
    ? []
    : [
        {
          first_name: state?.agent?.first_name,
          last_name: state?.agent?.last_name,
          name:
            (state?.agent?.first_name as string) +
            (state?.agent?.last_name && ` ${state?.agent?.last_name}`),
          email: state?.agent?.email,
          mobile_number: `+${state?.agent?.mobile_number}`,
        },
      ],
  task: state?.task,
})
